html, body {
  font-family: 'Nunito', sans-serif;
}


.cleaning-form {
  border-radius: 10px;
  border-width: 1.5px;
  -webkit-box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.05);
  box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.05);
  font-size: 1em !important;
}

.light-shadow {
  -webkit-box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.05);
  box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.05);
}

.flexbox {
  display: flex;
}

.err-msg {
  font-weight: bolder;
  color: red;
  margin-top: 2vh;
}

.err-msg-form {
  font-weight: bolder;
  color: red;
  margin-top: 1vh;
}

.succ-msg {
  font-weight: bolder;
  color: green;
}

.signup-image {
  width: 60%;
  margin-right: 10vw;
}

.login-image {
  width: 25%;
  margin-bottom: 1vh;
}

.confirm-image {
  width: 50%;
  margin-bottom: 1vh;
}

.required-field {
  color: red;
}

.cleaning-auth-card {
  border-radius: 15px;
}

.cleaning-login-card {
  border-radius: 15px;
}

.cleaning-form-label {
  font-weight: bolder;
  font-size: 1em;
  color: black !important;
}

.cleaning-btn-register {
  font-weight: bolder;
  font-size: 1rem;
  border-radius: 10px;
  width: 27.5vw;
  margin-left: 5px;
  margin-bottom: 5px;
  -webkit-box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.10);
  box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.10);
}

.cleaning-btn-login {
  font-weight: bolder;
  font-size: 1rem;
  border-radius: 10px;
  margin-left: 5px;
  margin-bottom: 5px;
  -webkit-box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.10);
  box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.10);
}

.cleaning-btn-delete {
  font-weight: bolder;
  color: white !important;
  font-size: 1rem;
  border-radius: 10px;
  margin-left: 5px;
  margin-bottom: 5px;
  -webkit-box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.10);
  box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.10);
}

.cleaning-modal {
  border-radius: 15px !important;
}

.div-cleaning-dashboard {
  display: flex;
  align-items: center;
}

.img-cleaning-dashboard {
  width: 30%;
  margin-bottom: 3vh;
}

.img-cleaning-dashboard-disabled {
  width: 30%;
  margin-bottom: 3vh;
  opacity: 0.4;
}

.img-aree-ambienti {
  width: 100%;
  margin-bottom: 3vh;
}

.title-cleaning-dashboard {
  font-weight: 600;
  color: #2f55d4;
}

.title-cleaning-dashboard-disabled {
  font-weight: 600;
  color: #2f55d4;
  opacity: 0.4;
}

.subtext-cleaning-dashboard {
  color: rgb(132, 146, 166);
  font-size: 1em;
  text-align: justify;
}

.subtext-cleaning-dashboard-disabled {
  color: rgb(132, 146, 166);
  font-size: 1em;
  text-align: justify;
  opacity: 0.4;
}

.sidebar-cleaning-logo {
  max-width: 20vh;
  margin-top: 2vh;
}

.cleaning-cancel-btn {
  background-color: white !important;
  border-color: #495057 !important;
  color: #495057;
  border-radius: 10px;
  font-weight: bolder;
}

.cleaning-cancel-btn:focus {
  background-color: #495057 !important;
  border-color: #495057 !important;
  color: white;
}

.account-delete-link {
  color: red !important;
  cursor: pointer;
}

.btn-cleaning {
  border-radius: 10px;
  font-weight: bolder;
}

.change-image-badge {
  margin-left: -10px;
  margin-bottom: -10px;
  background-color: #cccccc;
  color: black;
  border-radius: 100px !important;
  font-size: 1.2em;
  -webkit-box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.60);
  box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.60);
}

.dropdown-cleaning-image-profile {
  background-color: none !important;
}

.bookmark {
  display: none !important;
}

.remove-bg-cleaning {
  background-color: none !important;
}
.react-bootstrap-table-pagination {
  margin-top: 2vh;
}

.react-bootstrap-table-page-btns-ul {
  float: right;
}
.immagine-attrezzi {
  width: 15%;
}

.immagine-profilo-materiale {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%
}

.immagine-profilo-materiale-edit {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%
}

.titolo-profilo-materiale {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.dettaglio-profilo-materiale {
  font-size: 1.2em;
}

.strong-profilo-materiale {
  font-weight: bolder;
}

.titolo-dettaglio {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.cleaning-btn-scheda {
  font-weight: bolder;
  font-size: 1rem;
  border-radius: 10px;
  margin-left: 5px;
  margin-bottom: 5px;
  -webkit-box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.10);
  box-shadow: 3px 5px 26px -7px rgba(0,0,0,0.10);
  color: white !important;
}

.file-viewer-cleaning {
  max-height: 10vh;
}

.cleaning-wizard-icons {
  color: blue;
  font-size: 1.5rem;
}

.cleaning-doc-thumbnail {
  width: 20%;
}

div.documento-cleaning-a {
  background: transparent;
}

iframe.docpupu {
  z-index: -2 !important;
}

#react-doc-viewer {
  height: 80vh;
  margin-bottom: -10vh;
}

.ndfHFb-c4YZDc-Wrql6b {
  display: none !important;
}

.rstm-toggle-icon-symbol {
  font-size: 1.5em;
  font-weight: bolder;
  color: green;
}

.rstm-tree-item-level0 {
  font-size: 1.5em;
  font-weight: bolder
}

.rstm-tree-item-level1 {
  font-size: 1.2em;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.rstm-search {
  border-radius: 10px;
  padding-top: 0.375rem;
  padding-right: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  border: 1.5px solid #ced4da;
  background-color: #ffffff;
  box-shadow: 3px 5px 26px -7px rgb(0 0 0 / 5%);
  font-size: 1em;
}

.goback-cleaning {
  margin-left: 1vw;
  font-size:1.3em;
  font-weight: 600;
  color: blue;
}

.card-preventivo {
  cursor: pointer;
}

.row-preventivo {
  border: 1px solid #efefef;
  border-radius: 10px;
  padding-top: 2vh;
  padding-bottom: 2vh;
  margin-top: 4vh;
  margin-bottom: 4vh;
}

.titolo-row-preventivo {
  margin-left: 1rem;
  font-weight: bolder;
  margin-bottom: 2vh !important;
}

.titolo-sezione-preventivo {
  font-weight: bolder;
  font-size: 2em;
  color: #143FEF;
  margin-bottom: 2vh !important;
}
.paragraph-preventivo {
  background-color: #EFEFEF;
  padding: 2vh;
  border-radius: 10px;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.list-cleaning-preventivo {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #143FEF !important;
}

.img-list-preventivo {
  width: 7% !important;
  height: 7% !important;
}

.cleaning-form-label-operazioni {
  font-weight: bolder;
  font-size: 1.5em;
  color: black !important;
}

.checkbox-operazioni {
  margin-right: 1vw;
}

.spinner-loading-cleaning {
  left: 50%;
  margin: auto;
  position: absolute;
}

.table-preventivo-operazioni {
  margin-bottom: 4vh;
  overflow-x: scroll;
}

.font-reduce {
  font-size: 0.9em;
}

.sidebar-operazioni-preventivo {
  border: 1px solid #efefef;
  border-radius: 15px;
  padding: 10px;
}

.div-tabella {
  overflow-x: scroll;
  min-height: 60vh;
}

.table-preventivo {
  border: 1px solid lightgrey;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.table-preventivo-td {
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  padding: 1vh;
  min-width: 350px;
}

.table-preventivo-head {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 2vh;
  border-bottom: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
}

.table-input-preventivo {
  border: 1px solid lightgrey;
  padding: 5px;
}

.table-input-preventivo:focus {
  border: 5px solid rgba(30,144,255,0.3);
  padding: 5px;
}

.input-select-preventivi {
  min-width: 15vw;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
}

.table-preventivo-paginazione {
  right: 2vw;
  margin-bottom: 4vh;
}

.spacing {
  margin-top: 2vh;
  margin-bottom: 2vh;
}


.table-sticky-column {
  position: sticky;
  left: -1px;
  background: white;
  z-index: 1;
  padding: 1vh;
  border-bottom: 1px solid lightgrey;
  border-right: 2px solid lightgrey;
  border-left: 1px solid lightgrey;
  -webkit-box-shadow: 6px 0px 13px -5px #000000;
  -moz-box-shadow: 6px 0px 13px -5px #000000;
  -o-box-shadow: 6px 0px 13px -5px #000000;
  box-shadow: 6px 0px 13px -5px #000000;
}

.cleaning-disabled:disabled {
  background-color: rgba(255,255,0, 0.3) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.div-progetto {
  margin-top: 4vh;
}

.progetto-titolo-capitolo {
  font-size: 1.3em;
  font-weight: 600;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.progetto-titolo-paragrafo {
  font-size: 1.2em;
  font-weight: 600;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.progetto-titolo-documento {
  font-size: 1.1em;
  margin-right: 2vw;
}

.span-select-operazioni {
  color: #4466f2;
  cursor: pointer;
}

.toggle-eye {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 0
}

.toggle-book {
  margin-top: 0.5vh;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 10px;
  cursor: pointer;
  color: rgb(68, 102, 242);
}
